import axios from 'axios'

export function getArticles(params) {
  return axios.request({
    url: '/article/queryArticleByTopicCode',
    method: 'post',
    data:JSON.stringify(params),
    headers: { 'content-type': 'application/json' }
  })
}


export function getSingleArticle(articleId) {
  return axios.request({
    url: '/article/getArticleById',
    method: 'post',
    data:JSON.stringify({articleId:articleId}),
    headers: { 'content-type': 'application/json' }
  })
}